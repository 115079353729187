import store from '../store';

const getMenu = function (auction, tutorialMenuItems) {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Home',
            link: '/'
        },
        {
            text: 'About Us',
            link: '/about/',
            items: [
                {
                    icon: '',
                    text: 'Our Team',
                    link: '/our-team/'
                },
                {
                    icon: '',
                    text: 'Services',
                    link: '/services/'
                }
            ]
        }
    ];

    if (auction.length !== 0) {
        menu.push({
            icon: '',
            text: 'Auction',
            link: '#',
            items: auction
        });
    } else {
        menu.push({
            icon: '',
            text: 'Auction',
            link: '/auctions/upcoming/'
        });
    }

    const tutorialItemsMenu = [];

    if (tutorialMenuItems.userManual.length !== 0) {
        tutorialItemsMenu.push({
            text: 'User Manual',
            items: tutorialMenuItems.userManual,
            class: 'menu-level-2'
        });
    }

    if (tutorialMenuItems.tutorials.length !== 0) {
        tutorialItemsMenu.push({
            text: 'Video Tutorials',
            items: tutorialMenuItems.tutorials,
            class: 'menu-level-2'
        });
    }

    if (tutorialItemsMenu.length !== 0) {
        menu.push({
            text: 'Tutorial',
            link: '#',
            items: tutorialItemsMenu
        });
    }

    menu.push(
        {
            icon: '',
            text: 'FAQ',
            link: '/faq/'
        },
        {
            text: 'Media',
            link: '#',
            items: [
                {
                    text: 'News',
                    link: '/media/news/'
                },
                {
                    text: 'Blogs',
                    link: '/media/blog/'
                }
            ]
        },
        {
            icon: '',
            text: 'Contact Us',
            link: '/contact/'
        },
        {
            icon: '',
            class: 'nav-btn-basic-a',
            text: 'Register',
            link: '/register/'
        },
        {
            icon: '',
            class: 'nav-btn-basic-b ml-lg-3 text-center',
            text: 'Login',
            link: '/login/'
        }
    );

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }

    return menu;
};

export default getMenu;
