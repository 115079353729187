<template>
    <div class="" id="top">
        <notifications/>

        <top-header v-if="details.social_media" :details="details" :current-route="$router.currentRoute.name"
                    class="d-sm-none d-md-none"></top-header>

        <top-header-mobile v-if="details.social_media" :details="details" class="d-lg-none"></top-header-mobile>

        <div class="nav-container top-margin pt-lg-4">
            <lego-nav-bar @link-event="linkEvent" drop-down-animation="fade" drop-down-animation-mobile="fade"
                          :colored="true" :items="menu" shadow="0">

                <template #logo>
                    <router-link to="/">
                        <img src="../assets/mobile/home/logo/Bank-e-Auctions.png" loading="lazy" alt="">
                    </router-link>
                </template>

            </lego-nav-bar>
        </div>

        <whats-new-scrolling class="mt-1" :details="details.whats_new"
                             v-if="$router.currentRoute.name === 'Home' && details.whats_new.length > 0"/>

        <whats-new-scrolling class="mt-1" :details="['No news available.']"
                             v-if="$router.currentRoute.name === 'Home' && details.whats_new.length===0"/>

        <div class="bg-2 pt-8"
             :class="{'pt-home' : $router.currentRoute.name === 'Home',
             'pt-inner-page' :$router.currentRoute.name !== 'Home'}">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <Footer/>
        <Cookies/>
        <main-footer :details="details" :auction-link="auctionLink" :current-route="$router.currentRoute.name"></main-footer>

        <questionaire-chat-modal></questionaire-chat-modal>

        <scroll-to-top size="xl"></scroll-to-top>
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import TopHeader from '../components/home/TopHeader';
import TopHeaderMobile from '../components/home/TopHeaderMobile';
import WhatsNewScrolling from '../components/home/WhatsNewScrolling';
import MainFooter from '../components/MainFooter';
import QuestionaireChatModal from '../components/home/QuestionaireChatModal';
import ScrollToTop from '../components/ui/ScrollToTop';
import axios from 'secure-axios';
import urls from '../data/urls';
import Cookies from '../views/components/single-components/cookies';

export default {
    components: {
        Cookies,
        ScrollToTop,
        QuestionaireChatModal,
        MainFooter,
        WhatsNewScrolling,
        TopHeaderMobile,
        TopHeader,
        LegoNavBar
    },

    data () {
        return {
            details: {
                social_media: [],
                whats_new: []
            },
            auctionLink: '#',
            dataLoading: false,
            // menu: getMenu(this.auctionOptions),
            tutorial_menu: {},
            value: '',
            menu: [],
            auctionOptions: null
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    metaInfo: {
        titleTemplate: '%s - Project Name',

        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },

    name: 'MainLayout',

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.socialMedia);
            const json = response.data;
            if (json.error === false) {
                this.details = { ...json.data };
                this.tutorial_menu = json.data;
                this.setOptions(json.links.data);
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },

        linkEvent (name) {
            if (name === 'goto-register') {
                this.$router.push('/register/');
            }

            if (name === 'user-manuel') {
                this.getUserManual();
            }

            if (name.split(' ')[0] === 'tutorial') {
                this.showTutorial(name.split(' ')[1]);
            }

            if (name.split(' ')[0] === 'user-manual') {
                this.showUserManual(name.split(' ')[1]);
            }
        },

        showTutorial (index) {
            window.open(this.tutorial_menu.tutorial[index].tutorial, '_blank');
        },

        showUserManual (index) {
            window.open(this.tutorial_menu.user_manual[index].user_manual, '_blank');
        },
        setOptions (links) {
            const propertyMenu = this.getPropertyMenu(links);
            this.auctionLink = propertyMenu.length > 0 ? propertyMenu[0].link : null;
            const tutorialMenu = this.getTutorialMenu();
            this.menu = getMenu(propertyMenu, tutorialMenu);
        },
        getPropertyMenu (links) {
            const propertyMenu = [];
            for (let i = 0; i < links.length; i++) {
                propertyMenu.push({ text: links[i].label, link: '/auctions/' + links[i].label + '/' });
            }
            return propertyMenu;
        },
        getTutorialMenu () {
            const tutorial = [];
            const userManual = [];
            if (this.tutorial_menu.tutorial.length !== 0) {
                for (let i = 0; i < this.tutorial_menu.tutorial.length; i++) {
                    tutorial.push({ text: this.tutorial_menu.tutorial[i].title, event: 'tutorial ' + i });
                }
            }
            if (this.tutorial_menu.user_manual.length !== 0) {
                for (let i = 0; i < this.tutorial_menu.user_manual.length; i++) {
                    userManual.push({ text: this.tutorial_menu.user_manual[i].title, event: 'user-manual ' + i });
                }
            }
            return {
                userManual: userManual,
                tutorials: tutorial
            };
        }
    }
};
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    .pt-home {
        padding-top: 7.75rem !important;
        @media (min-width: 992px) {
            padding-top: var(--spacer-8) !important;
        }
    }

    .pt-inner-page {
        padding-top: 7rem !important;
        @media (min-width: 992px) {
            padding-top: var(--spacer-8) !important;
        }
    }

    .notification {
        /*fix for responsive issue in mobile screens
        Notification is not completely visible there*/
        @media (max-width: 768px) {
            width: 80% !important;
        }
    }
</style>
