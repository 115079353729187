<template>
    <div class="fl-x fl-a-c">
        <div class="w-7p border-rounded text-center fl-x-cc mb-3 mb-lg-0 mr-3">
            <img src="../../assets/web/home/BANK-E-AUCTION-LOGO-icon.png" alt="" class="img-fluid">
        </div>
        <div class="text-left">
            <p class="mb-0 font-inter-regular">Bank e Auctions India.com</p>
            <p class="font-inter-light mb-0 fs-1 p-2 bg-4 border-r--2">{{message}}</p>
            <p class="font-inter-extra-light fs--1">{{time}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatMessageItem',
    props: {
        message: {
            type: String,
            default: 'Hello, Greetings from Bank e Auctions India.com!'
        },
        time: {
            type: String,
            default: '8:31 PM'
        }
    }
};
</script>

<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}
.w-7p {
    width: 9%;
}
.border-rounded{
  border-radius: 50%;
}
</style>
