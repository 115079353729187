<template>
    <div class="bs-2 w-100 mb-3">
        <header-tag :heading="heading" color="primary">
            <template #buttons>
                <transition name="fade-accordion" mode="out-in">
                    <btn size="sm" icon="fa fa-chevron-down text-white cursor-ponter" class="border-rounded lego-btn-transparent" color="secondary"
                         key="open-btn" @click="toggleSection" v-if="!sectionVisibility"></btn>
                    <btn size="sm" icon="fa fa-chevron-up cursor-ponter text-white" class="border-rounded lego-btn-transparent" color="secondary"
                         key="close-btn" @click="toggleSection" v-else></btn>
                </transition>
            </template>
        </header-tag>
        <transition name="fade">
            <section class="" v-if="sectionVisibility">
                <slot></slot>
            </section>
        </transition>
    </div>
</template>
<script>
import HeaderTag from '@components/HeaderTag';

export default {
    name: 'BaseAccordion',
    components: { HeaderTag },
    data () {
        return { sectionVisibility: false };
    },
    props: { heading: { type: String } },
    methods: {
        toggleSection () {
            this.sectionVisibility = !this.sectionVisibility;
        }
    }
};
</script>
<style scoped> .fade-accordion-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-accordion-enter, .fade-accordion-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
} </style>
