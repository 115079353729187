<template>
    <a href="#" v-show="showNavBtn" @click.prevent="scrollTop" class="z-index-mx scroll-to-top text-white"
       :class="{'sm-btn':size === 'sm','lg-btn':size === 'lg','xl-btn':size==='xl'}">
        <!--        <i :class="iconClass"></i>-->
        <img src="../../assets/web/home/icons/up-arrow.png" alt="" class="arrow-img">
    </a>
</template>

<script>
export default {
    name: 'ScrollToTop',
    props: {
        icon: {
            type: String,
            default: 'fa fa-angle-double-up'
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    data () {
        return {
            showNavBtn: false
        };
    },
    computed: {
        iconClass () {
            const names = [this.icon];
            if (this.size === 'lg' || this.size === 'xl') {
                names.push('fa-2x');
            }
            return names;
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollTop () {
            window.scrollTo(0, 0);
        },
        handleScroll () {
            if (window.scrollY > 500) {
                this.showNavBtn = true;
            } else {
                this.showNavBtn = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.scroll-to-top {
    position: fixed;
    right: 40px;
    bottom: 30px;
    background: var(--color-primary) none repeat scroll 0 0;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    //height: 35px;
    //margin-right: 6px;
    text-align: center;
    //width: 35px;
    //border:1px solid var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;

    &.sm-btn {
        width: 25px;
        height: 25px;
    }

    &.lg-btn {
        width: 35px;
        height: 35px;
    }

    &.xl-btn {
        width: 60px;
        height: 60px;
    }
}

.arrow-img {
    width: 60px;
    height: 60px;
}

</style>
