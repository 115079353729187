<template>
    <cookie-law theme="dark-lime" class="py-4">
        <div class="cookie-disclaimer" slot="message">
            <div class="row fl-j-c">
                <div class="col-md-8 mb-3">
                    <div class="proxim-regular text-center">
                        This
                        website uses cookies to provide necessary site functionality and improve your online experience.
                        By
                        using this website, you agree to the use of cookies as outlined in <span class="font-inter-bold"> Bank e Auctions India.com
                        <router-link to="/privacy-and-policy/" class="text-primary text-decoration-none font-inter-bold"> Privacy Policy</router-link> </span>
                    </div>
                    <div class="col-md-2 "></div>
                </div>
            </div>
        </div>
    </cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
    name: 'cookies',
    components: { CookieLaw }
};
</script>

<style lang="scss">

.Cookie--dark-lime .Cookie__button {
    background: var(--color-secondary) !important;
    padding: 0.625em 3.125em !important;
    color: #fff !important;
    border-radius: 0 !important;
    border: 0 !important;
    font-size: 1em !important;

    &:hover {
        background: var(--color-secondary) !important;
        opacity: 0.8 !important;
    }
}

.Cookie--dark-lime {
    background: #000000 !important;
    color: #fff !important;
    opacity: 0.9 !important;
    padding: 1.250em !important;
}

.Cookie {
    flex-flow: column !important;
        justify-content: center !important;
    align-items: center !important;
}
</style>
