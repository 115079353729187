<template>
    <div id="main-contain fs--1" class="main-contain bg-2">
        <div class="container">
            <div class="news-scroll-contain bg-2">
                <div class="fl-x section-title text-primary fs--1 font-weight-medium pos-r">
                    <span class="pos-r fl-x fl-a-c">
                        <span class="fs--2 font-inter-medium pl-3 pl-lg-0">WHATS NEW</span>
                        <span class="fl-x fl-a-c ml-1">
                            <img class="" src="../../assets/mobile/home/icons/chevron-double-gray-16.png"
                                                       alt=""></span>
                    </span>

                    <!--                    <span>>> <i class="fa fa-eye m-0"></i></span>-->
                    <div class="text-primary">
                        <!--                        <i class="fa fa-chevron-right m-0 font-weight-light fs&#45;&#45;2 text-3"></i>-->
                    </div>
                </div>
                <span class="fl-x news-items">
                <marquee class="" behavior="scroll" direction="left">
                    <div class="fl-x">
                        <div class="fl-x fl-a-c px-2" v-for="(n,i) in details" :key="i">
                            <span class="avatar-container mr-3">
                                <div class="bullet"></div>
                            </span>
                            <span
                                class="fs-lg-0 font-weight-medium text-primary">
                                <router-link class="text-decoration-none" to="#">
                                    {{n}}
                                </router-link>
                                </span>
                        </div>
                    </div>

                    </marquee>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WhatsNewScrolling',

    props: { details: { type: Array, default: null } },

    data () {
        return {
            news: []
        };
    }
};
</script>

<style scoped lang="scss">
//.main-contain {
//    width: 100%;
//    display: flex;
//
//    span {
//        width: 100%;
//    }
//}

.bullet {
    width: 0.4rem;
    height: 0.4rem;
    background-color: var(--color-success);
    border-radius: 50%;
}

.main-contain {
    position: fixed;
    //position: relative;
    font-family: Arial;
    top: 0;
    left: 0;
    //background: rgba(0, 0, 0, 0.8);
    //background: var(--bg-1) !important;
    z-index: 990;
    height: 2rem !important;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        background-color: var(--bg-2);
        height: 0.6rem;
        width: 100%;
        z-index: -999;
    }

}

.root-contain {
    width: 100vw;
    position: relative;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        //background-color: var(--bg-2);
        height: 1rem;
        width: 100%;
        z-index: 990;
    }
}

.news-scroll-contain {
    position: absolute;
    //top: 50%;
    //top: 147px;
    //top: 140px;
    top: 121px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    //max-width: var(--container-max-width-xl);
    padding: 0;
    display: flex;
    justify-content: center;
    @media (min-width: 768px) and (max-width: 1024px) {
        top: 122px;
    }

    .section-title {
        position: relative;
        width: 40%;
        @media (min-width: 992px) {
            width: 10%;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            background: url("../../assets/mobile/home/double-arrow.png");
            height: 0.8rem;
            width: auto;
        }
    }

    @media (min-width: 992px) {
        /*top: 159px;*/
        top: 166px;
        padding: 0 100px;
    }

    .news-items {
        width: 90%;
    }
}

.breakingtitle {
    font-size: 50px;
    background: black;
    color: #FFD700;
    padding: 10px;
}

#readmorebutton {
    background: black;
    color: #FFD700;
    border-style: none;
    padding: 20px;
    font-size: 20px;
}

.marquee {
    color: black;
    letter-spacing: 2px;
    font-weight: 700;
    padding: 30px;
    font-size: 40px;
}

</style>
