<template>
    <ul class="list-unstyled ml-5">
        <li v-for="(item,i) in items" :key="i">
            <div class="my-2 fl-x fl-a-c p-2 bg-2 bs-5 chat-select-item cursor-pointer border-r--2"
                 :class="{'bg-secondary':activeItemIndex === i}" @click="toggleSelection(i,item.text)">
                <span class="avatar-container mr-2">
                    <div style="" class="bullet"></div>
                </span>
                <span>How to Register at Bank e Auctions India.com?</span>
            </div>
        </li>
    </ul>

</template>

<script>
export default {
    name: 'ChatSelectionItem',
    data () {
        return {
            active: false,
            activeItemIndex: null,
            activeSelection: null,
            items: [
                {
                    text: 'How to Register At Bank e Auctions India.com?'
                },
                {
                    text: 'Details about legal information'
                }
            ]
        };
    },
    methods: {
        toggleSelection (idx, text) {
            // this.bgClass = 'bg-secondary';
            this.activeItemIndex = idx;
            this.activeSelection = text;
            this.active = !this.active;
        }
    }
};
</script>

<style scoped lang="scss">

.chat-select-item {
    position: relative;
    transition: all 300ms ease-in-out;

    .bullet {
        width: 0.6rem;
        height: 0.6rem;
        background-color: var(--bg-2);
        border: 1px solid gray;
        border-radius: 50%;
    }

    //:hover {
    //    background-color: var(--color-secondary);
    //}
}
</style>
